"use client";

import { cn } from "@/libs/utils";
import { getCookie, setCookie } from "cookies-next";
import { CookieIcon } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Button } from "../ui/Button";

const COOKIE_NAME = "cookieConsent";

const CookieConsent: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [hide, setHide] = useState(false);

	useEffect(() => {
		const consentCookie = getCookie(COOKIE_NAME);

		if (!consentCookie) {
			setIsOpen(true);
		}
	}, []);

	const handleAccept = () => {
		setCookie(COOKIE_NAME, "true", { maxAge: 365 * 24 * 60 * 60 });
		setIsOpen(false);
		setHide(true);
	};

	const handleDecline = () => {
		setCookie(COOKIE_NAME, "false", { maxAge: 365 * 24 * 60 * 60 });
		setIsOpen(false);
		setHide(true);
	};

	if (!isOpen) return null;

	return (
		<div
			className={cn(
				"fixed z-[200] bottom-0 right-0 sm:right-0 sm:left-0 sm:bottom-4 w-full sm:max-w-full duration-700 max-w-lg",
				!isOpen
					? "transition-[opacity,transform] translate-y-8 opacity-0"
					: "transition-[opacity,transform] translate-y-0 opacity-100",
				hide && "hidden"
			)}
		>
			<div className="dark:bg-card bg-background/90 backdrop-blur-sm rounded-2xl m-3 border border-border shadow-lg">
				<div className="grid gap-2">
					<div className="border-b border-border h-14 flex items-center justify-between p-4">
						<h2 className="text-lg font-medium">Мы используем файлы cookie</h2>
						<CookieIcon className="h-[1.2rem] w-[1.2rem]" />
					</div>
					<div className="p-4">
						<p className="text-sm font-normal text-start">
							Мы используем файлы cookie, чтобы обеспечить лучший опыт использования нашего сайта. Подробнее о том, как
							мы используем файлы cookie, вы можете узнать в нашей политике cookie.
							<br />
							<br />
							<span className="text-xs">
								Нажимая "<span className="font-medium opacity-80">Принять</span>", вы соглашаетесь с использованием
								файлов cookie.
							</span>
							<br />
							<Link href="/privacy-policy" className="text-xs underline">
								Узнать больше.
							</Link>
						</p>
					</div>
					<div className="flex gap-2 p-4 py-5 border-t border-border dark:bg-background/20">
						<Button onClick={handleAccept} className="w-full text-sm" size={"sm"}>
							Принять
						</Button>
						<Button onClick={handleDecline} className="w-full text-sm" size={"sm"} variant="secondary">
							Отклонить
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookieConsent;
