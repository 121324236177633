import type { MutableRefObject } from "react";

type Props = {
	buttonRef: MutableRefObject<HTMLDivElement | null>;
};

const BackToTopActions = ({ buttonRef }: Props) => {
	const OFFSET_TOP = 600;

	const handleScrollToTop = () => {
		if (window?.scrollTo) {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	};

	const scrollListener = (): void => {
		const btnEl = buttonRef.current;

		if (window.scrollY < OFFSET_TOP) {
			btnEl?.classList.add("hidden");
		}
		if (window.scrollY >= OFFSET_TOP) {
			btnEl?.classList.remove("hidden");
			btnEl?.classList.add("flex");
		}
	};

	const addScrollListener = (): void => {
		document.addEventListener("scroll", scrollListener);
	};

	const removeScrollListener = (): void => {
		document.removeEventListener("scroll", scrollListener);
	};

	return { addScrollListener, removeScrollListener, handleScrollToTop };
};

export default BackToTopActions;
