"use client";

import { deleteCookie } from "cookies-next";

import { useUserContext } from "@/app/providers/UserProvider";

const CurrentUser: React.FC = () => {
	const user = useUserContext();

	if (!user) return null;
	const handleLogout = () => {
		deleteCookie("auth");
		window.location.reload();
	};

	return (
		<div className="w-full flex justify-end pt-2">
			<div className="w-full flex justify-end bg-white shadow-sm p-1 rounded-md items-center px-4">
				<span>{user.email as string}</span>
				<button className="ml-6 text-sm" onClick={handleLogout} type="button" aria-label="Выйти">
					Выйти
				</button>
			</div>
		</div>
	);
};

export default CurrentUser;
