import { useRef } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import useScrollLock from "@/hooks/useScrollLock";
import { cn } from "@/libs/utils";
import FooterMap from "@/presentation/components/FooterMap";
import FooterSocial from "@/presentation/components/FooterSocial";
import MetroAdressBlock from "@/presentation/components/MetroAdressBlock";
import { ThemeColor } from "@/types";

type ContactsProps = {
	closeContacts: () => void;
	isOpen: boolean;
};

const Contacts: React.FC<ContactsProps> = ({ closeContacts, isOpen }) => {
	const ref = useRef(null);
	useScrollLock(isOpen);

	return (
		<div
			ref={ref}
			className={cn(
				"bg-backgroundColor overflow-y-auto h-full flex flex-col gap-6 fixed top-0 left-0 py-6 px-4 w-full z-[999] transition duration-200 -translate-y-full",
				isOpen ? "translate-y-0" : ""
			)}
		>
			<div className="flex items-center justify-between">
				<p className="font-medium text-2xl">Контакты</p>
				<button onClick={closeContacts} aria-label="Закрыть контакты" name="close" type="button">
					<XPlitkaIcon name="cross" size={24} color={ThemeColor.accentGreen} />
				</button>
			</div>
			<div className="text-lg flex flex-col font-medium gap-2">
				<a href="tel:88007079025">8 (800) 707-90-25</a>
				<a href="mailto:zakaz@xplit.ru">zakaz@xplit.ru</a>
			</div>
			<MetroAdressBlock className="mt-0" variant="dark" />
			<FooterMap />
			<FooterSocial />
		</div>
	);
};

export default Contacts;
