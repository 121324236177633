import type { ItemResponse, OrderAmountResponse } from "@/data/__generated__/types/generated";

import toast from "react-hot-toast";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { ThemeColor } from "@/types";

export interface StoreItem {
	data: ItemResponse;
	orderBlock: OrderAmountResponse[];
}

export interface StoreState {
	addItem: (item: StoreItem) => void;
	getItems: () => StoreItem[];
	getTotalItems: () => number;
	items: StoreItem[];
	removeItem: ({ id }: { id: number }) => void;
	resetItems: () => void;
	updateItemOrderBlock: ({
		newOrderBlock,
		productId,
	}: {
		newOrderBlock: OrderAmountResponse[];
		productId: number;
	}) => void;
}

const useStore = create<StoreState>()(
	persist(
		(set, get) => ({
			items: [],
			addItem: (item: StoreItem) => {
				set((state) => {
					if (state.items.some((existingItem) => existingItem.data.id === item.data.id)) {
						return state;
					}
					const newItems = [item, ...state.items];

					return { ...state, items: newItems };
				});
				toast.success("Товар добавлен в Корзину.", {
					iconTheme: {
						primary: ThemeColor.accentGreen,
						secondary: ThemeColor.white,
					},
				});
				window.ym(9819136, "reachGoal", "add_to_cart");
			},
			removeItem: ({ id }: { id: number }) => {
				set((state) => ({
					...state,
					items: state.items.filter((item) => item.data.id !== id),
				}));
			},
			getItems: () => {
				return get().items;
			},

			getTotalItems: () => {
				const products = get().items;
				return products.length;
			},

			updateItemOrderBlock: ({
				newOrderBlock,
				productId,
			}: {
				newOrderBlock: OrderAmountResponse[];
				productId: number;
			}) => {
				set((state) => {
					const itemIndex = state.items.findIndex((item) => item.data.id === productId);

					if (itemIndex !== -1) {
						const updatedItem: StoreItem = {
							data: state.items[itemIndex]?.data as ItemResponse,
							orderBlock: newOrderBlock,
						};

						const newItems = [...state.items];
						newItems[itemIndex] = updatedItem;

						return { ...state, items: newItems };
					}
					return state;
				});
			},

			resetItems: () => {
				set({ items: [] });
			},
		}),
		{
			name: "store-storage",
			storage: createJSONStorage(() => localStorage),
		}
	)
);

export default useStore;
