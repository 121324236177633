type MobileHeaderActionsArgs = {
	setContactsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileHeaderActions = ({ setContactsOpen }: MobileHeaderActionsArgs) => {
	const openContacts = () => {
		setContactsOpen(true);
	};

	const closeContacts = () => {
		setContactsOpen(false);
	};

	return {
		openContacts,
		closeContacts,
	};
};

export default MobileHeaderActions;
