"use client";

import { useState } from "react";

import Link from "next/link";
import { usePathname } from "next/navigation";
import Contacts from "./components/Contacts";
import LogoContacts from "./components/LogoContacts";
import Navigation from "./components/Navigation";
import SearchBar from "./components/SearchBar";
import useActions from "./hooks";

const MobileHeader: React.FC = () => {
	const pathname = usePathname();
	const isHomePage = pathname === "/";
	const [contactsOpen, setContactsOpen] = useState(false);

	const { closeContacts, openContacts } = useActions({
		setContactsOpen,
	});

	return (
		<>
			<div className="hidden sm:flex p-4 pb-0 w-full sm:flex-col z-50 ">
				<LogoContacts openContacts={openContacts} />
			</div>
			<Navigation />
			<SearchBar />
			{isHomePage && (
				<Link href="/sale" className="h-12 min-w-[187px] px-4 mt-4 hidden sm:block">
					<button
						type="button"
						className="relative ml-auto border hover:border-red-600 duration-500 group cursor-pointer text-red-50  overflow-hidden h-12 w-full  bg-red-800 p-2 flex justify-center items-center rounded-xl"
					>
						<div className="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-900 delay-150 group-hover:delay-75" />
						<div className="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-800 delay-150 group-hover:delay-100" />
						<div className="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-700 delay-150 group-hover:delay-150" />
						<div className="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-600 delay-150 group-hover:delay-200" />
						<div className="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-500 delay-150 group-hover:delay-300" />
						<p className="z-10 flex font-bold uppercase ">Распродажа</p>
					</button>
				</Link>
			)}
			<Contacts isOpen={contactsOpen} closeContacts={closeContacts} />
		</>
	);
};

export default MobileHeader;
