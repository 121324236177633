import type { CategoryResponse } from "@/data/__generated__/types/generated";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";
import Link from "next/link";

import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";

type CatalogCategoriesProps = {
	buttonClassName?: string;
	categories?: CategoryResponse[];
	handleCloseCategories: () => void;
	handleHoverCategory: (id: null | number) => () => void;
	handleOpenCategories: () => void;
	hoveredCategoryId: null | number;
	isCategoriesOpen: boolean;
};

const CatalogCategories: React.FC<CatalogCategoriesProps> = ({
	handleCloseCategories,
	handleHoverCategory,
	handleOpenCategories,
	hoveredCategoryId,
	isCategoriesOpen,
	buttonClassName,
	categories = [],
}) => {
	return (
		<div className="relative z-10 lg:hidden">
			<button
				onClick={handleOpenCategories}
				type="button"
				aria-label="Открыть каталог товаров"
				className={cn(
					"bg-accentGreen hover:bg-greenHover rounded-xl text-white min-w-[187px] h-12 flex items-center justify-center transition lg:min-w-12",
					buttonClassName
				)}
			>
				<XPlitkaIcon name={isCategoriesOpen ? "cross" : "menu"} size={24} color={ThemeColor.white} />
				<span className="ml-1 font-medium lg:hidden">Каталог товаров</span>
			</button>
			{isCategoriesOpen && (
				<div className="absolute bg-white rounded-xl flex flex-col shadow-xl">
					<ul className="w-max py-2 relative z-10">
						{categories?.map((category) => {
							return (
								<li
									key={category.id}
									onMouseEnter={handleHoverCategory(category.id)}
									onMouseLeave={handleHoverCategory(null)}
									className="hover:text-accentGreen hover:bg-backgroundColor flex items-center px-4 py-2 justify-between cursor-pointer gap-4 relative font-medium"
								>
									<Link
										prefetch={true}
										href={`/${category.slug}`}
										onClick={handleCloseCategories}
										className="text-primaryBlack  cursor-pointer"
									>
										{category.title}
									</Link>
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
										<title>Отtitle каталог товаров</title>
										<path d="M10 16L14 12L10 8" stroke="currentColor" strokeWidth="2" />
									</svg>
									{hoveredCategoryId === category.id && (
										<div className="absolute left-full top-0 px-1 h-full cursor-default">
											<ul className=" bg-white shadow-xl w-max rounded-xl py-2 max-h-[500px] overflow-y-auto">
												{category.childCategories.map((subcategory) => (
													<Link prefetch={true} href={`/${subcategory.slug}`} key={subcategory.slug}>
														<li
															onClick={handleCloseCategories}
															className="px-4 py-2 text-primaryBlack hover:text-accentGreen transition"
														>
															{subcategory.title}
														</li>
													</Link>
												))}
											</ul>
										</div>
									)}
								</li>
							);
						})}
					</ul>
					<div
						role="presentation"
						aria-label="Закрыть каталог товаров"
						className="fixed w-screen h-screen left-0 top-0  -z-10"
						onClick={handleCloseCategories}
					/>
				</div>
			)}
		</div>
	);
};

export default CatalogCategories;
