import CartButton from "../CartButton";
import FavoritesButton from "../FavoritesButton";

import BurgerMenu from "@/presentation/components/BurgerMenu";
import SearchBar from "@/presentation/components/SearchBar";

const Navigation: React.FC = () => {
	return (
		<div className="flex items-center w-full">
			<nav className="flex items-center gap-8 w-full">
				<div className="flex items-center gap-2 flex-1">
					<BurgerMenu />
					<SearchBar />
					<div className="flex gap-2 items-center lg:ml-auto">
						<FavoritesButton />
						<CartButton />
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navigation;
