import type { CountryResponse, ItemResponse, OrderAmountResponse } from "@/data/__generated__/types/generated";
import type { StoreItem } from "@/hooks/useStore";

import { FILTER_TITLES, IMAGE_URL } from "@/constants";

export const generateAlphabete = (): string[] => {
	return [...Array(26)].map((_e, i) => (i + 10).toString(36).toLocaleUpperCase());
};

export const determineCategory = (title: string) => {
	return FILTER_TITLES[title as keyof typeof FILTER_TITLES] || "other";
};

export const determineFilterGoupTitle = (category: string) => {
	const entry = Object.entries(FILTER_TITLES).find(([, value]) => value === category);
	return entry ? entry[0] : "Unknown";
};

export const getImageSource = ({
	type,
	width,
	height,
	thumbnail,
	brand,
}: {
	brand?: string;
	height?: number;
	thumbnail: string;
	type: "collections" | "items" | "links" | "news";
	width: number;
}) => {
	const brandPath = brand ? `/${brand}` : "";
	const size = height ? `${width}x${height}` : `${width}`;
	return `${IMAGE_URL}/${type}${brandPath}/${size}/${thumbnail}`;
};

export const detectParamType = (param: string, countries: CountryResponse[]) => {
	const isFirstLetter = /^[a-zA-Z]$/.test(param);
	const isCountrySlug = countries.some(({ slug }) => slug === param);

	return {
		countrySlug: isCountrySlug ? param : undefined,
		firstLetter: isFirstLetter ? param : undefined,
		manufacturerSlug: !isFirstLetter && !isCountrySlug ? param : undefined,
	};
};

export const formatPrice = (price?: null | number) => {
	if (!price) return "";

	return (price / 100)?.toLocaleString("en-US")?.replace(",", " ");
};

export const getPropertyValue = ({
	product,
	value,
	name,
}: {
	name?: string;
	product: ItemResponse;
	value: string;
}): { value: string; valueSlug: string } => {
	if (!product)
		return {
			value: "",
			valueSlug: "",
		};
	const item = product.properties.find(
		(property) => property.typePropertySlug === value || property.typeProperty === name
	);

	return {
		value: item?.value || "",
		valueSlug: item?.valueSlug || "",
	};
};

export const getProductSize = ({ product }: { product: ItemResponse }) => {
	const { value: width } = getPropertyValue({ product, value: "width" });
	const { value: length } = getPropertyValue({ product, value: "length" });
	const size = width && length ? `${Number(width) / 100}x${Number(length) / 100} cm` : null;

	return size;
};

export const calcSquareMetters = (product: ItemResponse) => {
	const { value: squareMetters } = getPropertyValue({
		product,
		value: "quantity_m",
	});

	if (squareMetters) {
		return Number(squareMetters).toFixed(3);
	}
	const { value: quantity } = getPropertyValue({ product, value: "quantity" });

	const { value: width } = getPropertyValue({ product, value: "width" });
	const { value: length } = getPropertyValue({ product, value: "length" });
	const widthValue = Number(width) / 100;
	const lengthValue = Number(length) / 100;

	return ((widthValue * lengthValue * Number(quantity)) / 10000).toFixed(3);
};

export const getPageLoaderTemplate = () => {
	return `<div class="bar" role="bar">
  <div class="peg"></div>
</div>
<div class="spinner" role="spinner">
  <div class="spinner-icon"></div>
</div>
`;
};

export const getAmountByKey = (orderBlock: OrderAmountResponse[], keys: string[]) => {
	for (const key of keys) {
		const value = orderBlock.find((tab) => tab.key === key)?.value;
		if (value !== undefined) {
			return key === "metter" ? value * 1000 : value;
		}
	}
	return 0;
};

export const calculatePrice = (product: ItemResponse, orderBlock: OrderAmountResponse[]) => {
	if (!product) return 0;
	const packTab = orderBlock.find((tab) => tab.key === "pack");
	if (!packTab) {
		const unitTab = orderBlock.find((tab) => tab.key === "unit");
		if (!unitTab || !product.price) return 0;
		return product.price * unitTab?.value;
	}

	const packValue = packTab.value;
	const pricePerPack = product.order.minOrderPrice || 0;

	return packValue * pricePerPack;
};

export const calculateTotalPrice = (products: StoreItem[]) => {
	return products.reduce((total, item): number => {
		const itemPrice = calculatePrice(item.data, item.orderBlock);
		return total + itemPrice;
	}, 0);
};

export const generateProductTitle = (product: ItemResponse) => {
	const propertyMap = new Map(product.properties.map(({ typeProperty, value }) => [typeProperty, value]));

	const size =
		propertyMap.get("Ширина") && propertyMap.get("Длина")
			? `${Number(propertyMap.get("Ширина")) / 100}x${Number(propertyMap.get("Длина")) / 100} cm`
			: null;
	const { value: purpose } = getPropertyValue({
		product,
		name: "Назначение",
		value: "purpose",
	});

	return `${purpose || ""} ${product?.title || ""} ${size || ""} ${product.collectionTitle || ""}`;
};
