"use client";

import type { CategoryResponse, ManufacturerGroupResponse } from "@/data/__generated__/types/generated";

import { useEffect, useState } from "react";

import BrandsList from "../BrandsList";
import CatalogCategories from "../CatalogCategories";

import { cn } from "@/libs/utils";

type FixedNavbarProps = {
	categories?: CategoryResponse[];
	handleCloseCategories: () => void;
	handleHoverCategory: (id: null | number) => () => void;
	handleOpenCategories: () => void;
	hoveredCategoryId: null | number;
	hoveredLetter: null | string;
	isCategoriesOpen: boolean;
	manufacturers?: ManufacturerGroupResponse[];
};

const FixedNavbar: React.FC<FixedNavbarProps> = ({
	categories = [],
	handleCloseCategories,
	handleHoverCategory,
	handleOpenCategories,
	hoveredCategoryId,
	hoveredLetter,
	isCategoriesOpen,
	manufacturers = [],
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 268) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			className={cn(
				"fixed top-0 left-0 bg-white/80 backdrop-blur-md shadow-sm h-max w-full transition-all duration-200 z-50",
				isVisible ? "translate-y-0" : "-translate-y-full"
			)}
		>
			<div className="flex items-center w-full md:hidden flex-col relative z-20 max-w-[1232px] px-4 mx-auto">
				<div className="flex items-center gap-4 w-full py-3">
					<CatalogCategories
						categories={categories}
						isCategoriesOpen={isCategoriesOpen}
						handleOpenCategories={handleOpenCategories}
						handleCloseCategories={handleCloseCategories}
						handleHoverCategory={handleHoverCategory}
						hoveredCategoryId={hoveredCategoryId}
						buttonClassName="h-[48px]"
					/>
					<BrandsList manufacturers={manufacturers} hoveredLetter={hoveredLetter} className="h-[48px]" />
				</div>
			</div>
		</div>
	);
};

export default FixedNavbar;
