import { useEffect, useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";
import Link from "next/link";

import useStore from "@/hooks/useStore";
import { ThemeColor } from "@/types";

const CartButton: React.FC = () => {
	const [isMounted, setIsMounted] = useState(false);
	const store = useStore();

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<Link
			href="/shop/checkout"
			className="bg-white rounded-xl min-w-[131px] h-12 flex justify-center items-center font-medium text-lg relative sm:min-w-[44px] sm:h-[44px]"
		>
			<XPlitkaIcon size={24} color={ThemeColor.accentGreen} name="cart" />
			<span className="ml-2 sm:hidden">Корзина</span>
			{isMounted && store.getTotalItems() > 0 && (
				<span className="bg-primaryBlack text-xs absolute -top-[10px] -right-[10px] rounded-full text-white px-2 py-1">
					{store.getTotalItems()}
				</span>
			)}
		</Link>
	);
};

export default CartButton;
