import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { ThemeColor } from "@/types";

const FooterSocial: React.FC = () => {
	return (
		<div className="flex items-center gap-4">
			<a href="https://vk.com/xplitka" target="_blank" rel="noreferrer" aria-label="Вконтакте">
				<XPlitkaIcon name="vkontakte" color={ThemeColor.accentGreen} size={34} />
			</a>
			<a href="https://t.me/xplitka" target="_blank" rel="noreferrer" aria-label="Телеграм">
				<XPlitkaIcon name="telegram" color={ThemeColor.accentGreen} size={34} />
			</a>
			<a
				className="bg-accentGreen text-white flex items-center rounded-xl h-12 pl-4 pr-6 sm:h-[44px] hover:text-white"
				href="https://wa.me/79251601043"
				target="_blank"
				rel="noopener noreferrer"
			>
				<XPlitkaIcon name="whatsup" color={ThemeColor.white} size={34} />
				<span className="font-medium text-lg ml-2 relative top-[1px]">Whatsapp</span>
			</a>
		</div>
	);
};

export default FooterSocial;
