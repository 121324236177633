"use client";

import { ApolloNextAppProvider } from "@apollo/experimental-nextjs-app-support/ssr";

import { getApolloClient } from "@/libs/apolloClient";

type ApolloProviderProps = {
	children: React.ReactNode;
};

const ApolloProvider: React.FC<ApolloProviderProps> = ({ children }) => {
	return <ApolloNextAppProvider makeClient={getApolloClient}>{children}</ApolloNextAppProvider>;
};

export default ApolloProvider;
