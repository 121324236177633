import Image from "next/image";

const HeaderLogo: React.FC = () => {
	return (
		<div>
			<a href="/" className="flex items-center">
				<Image
					width={48}
					height={48}
					priority
					className="mr-3.5"
					src="/static/images/lightSquareLogo.svg"
					alt="Square Logo"
				/>
				<Image
					width={116}
					height={20}
					priority
					className="xl:hidden"
					src="/static/images/lightLogoText.svg"
					alt="Text Logo"
				/>
			</a>
		</div>
	);
};

export default HeaderLogo;
