"use client";

import type { JWTPayload } from "jose";

import { createContext, useContext } from "react";

export type TUserPayload = {
	email: string;
	exp: number;
	iat: number;
	id: number;
};

const UserContext = createContext<JWTPayload | null>(null);

export function useUserContext() {
	return useContext(UserContext);
}

type UserProviderProps = {
	children: React.ReactNode;
	session: JWTPayload | null;
};

const UserProvider: React.FC<UserProviderProps> = ({ children, session }) => {
	return <UserContext.Provider value={session}>{children}</UserContext.Provider>;
};

export default UserProvider;
