import { useCallback, useEffect, useRef, useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import type { SuggestionsResponse } from "@/data/__generated__/types/generated";
import api from "@/data/api";
import { getImageSource } from "@/helpers";
import { useDebounce } from "@/hooks/useDebounce";
import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";
import { Loader2, Scan, SearchIcon } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "../../ui/Command";

const SearchBar: React.FC = () => {
	const router = useRouter();
	const [searchTerm, setSearchTerm] = useState("");
	const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
	const [suggestions, setSuggestions] = useState<SuggestionsResponse>({
		items: [],
		collections: [],
	});
	const [selectedTab, setSelectedTab] = useState<"collections" | "items">("items");
	const ref = useRef(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const debouncedSearchInput = useDebounce(searchTerm, 500);
	const {
		execute,
		result: { data, loading },
	} = api.suggestion.Suggestion({ fetchPolicy: "network-only" });

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const searchURL = `/search?searchid=2701484&web=0&text=${encodeURIComponent(searchTerm)}`;

		window.location.href = searchURL;
	};

	const handleSelect = ({ id, slug }: { id?: number; slug?: string }) => {
		if (slug) {
			router.push(`/catalog/${slug}`);
			return;
		}

		if (!id) return;

		const searchURL = `/search.html?searchid=2701484&web=0&text=${encodeURIComponent(id)}`;
		window.location.href = searchURL;
	};

	const open = useCallback(() => setIsSuggestionsOpen(true), []);
	const close = useCallback(() => setIsSuggestionsOpen(false), []);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	useEffect(() => {
		if (!debouncedSearchInput) {
			setSuggestions({
				items: [],
				collections: [],
			});
			return;
		}

		execute({ variables: { input: { keyword: debouncedSearchInput } } });
	}, [debouncedSearchInput, execute]);

	useEffect(() => {
		if (data?.suggestions) {
			setSuggestions(data?.suggestions || { items: [], collections: [] });
		}
	}, [data]);

	const mapData = selectedTab === "collections" ? suggestions?.collections : suggestions?.items;
	const showTabs = suggestions?.collections?.length > 0 && suggestions?.items?.length > 0;
	const isItems = selectedTab === "items";

	return (
		<Command
			inputMode="search"
			shouldFilter={false}
			className={cn(
				"w-full h-[48px] bg-backgroundColor gap-2 items-center px-4 transition duration-200 overflow-visible hidden sm:flex"
			)}
		>
			<form ref={ref} onSubmit={handleSubmit} className="w-full flex h-full items-center gap-2">
				<input
					ref={inputRef}
					placeholder="Поиск по каталогу"
					value={searchTerm}
					onChange={handleInputChange}
					onBlur={close}
					onFocus={open}
					className="bg-white rounded-xl h-12 w-full px-4 outline-none focus:border-accentGreen focus:border"
				/>
				<button
					type="submit"
					name="search"
					aria-label="Поиск"
					className="h-12 min-w-[48px] rounded-xl bg-accentGreen flex items-center justify-center"
				>
					<XPlitkaIcon name="search" size={24} color={ThemeColor.white} />
				</button>
			</form>
			{isSuggestionsOpen && (
				<div className="relative animate-in fade-in-0 zoom-in-95 h-auto w-full">
					<CommandList className="rounded-xl">
						<div className="absolute top-0 z-50 w-full">
							<CommandGroup className="relative h-auto z-50 min-w-[8rem] overflow-hidden rounded-xl border shadow-md bg-background max-h-[600px] overflow-y-scroll">
								{showTabs && (
									<div className="flex" onMouseDown={(e) => e.preventDefault()}>
										<button
											type="button"
											onClick={() => setSelectedTab("items")}
											className={cn(
												"border-b-2 px-4 py-1 mb-4 uppercase text-darkGray",
												selectedTab === "items" && "text-accentGreen font-medium border-accentGreen"
											)}
										>
											Товары
										</button>
										<button
											type="button"
											onClick={() => setSelectedTab("collections")}
											className={cn(
												"border-b-2 px-4 py-1 mb-4 uppercase text-darkGray",
												selectedTab === "collections" && "text-accentGreen font-medium border-accentGreen"
											)}
										>
											Коллекции
										</button>
									</div>
								)}
								{loading ? (
									<div className="h-28 flex items-center justify-center">
										<Loader2 className="size-6 animate-spin" />
									</div>
								) : (
									<>
										{mapData.map((suggestion) => (
											<CommandItem
												value={suggestion.title}
												key={suggestion.id}
												className="cursor-pointer"
												onSelect={() => handleSelect({ id: suggestion.id, slug: suggestion.slug })}
												onMouseDown={(e) => e.preventDefault()}
											>
												<div className="flex items-center gap-2  hover:text-accent-foreground cursor-pointer">
													<Image
														width={200}
														height={100}
														src={getImageSource(
															isItems
																? {
																		width: 280,
																		type: "items",
																		brand: suggestion.id.toString(),
																		thumbnail: suggestion.image || "",
																	}
																: {
																		height: 448,
																		width: 900,
																		type: "collections",
																		brand: suggestion.slug,
																		thumbnail: suggestion.image || "",
																	}
														)}
														alt={suggestion.title}
														className="h-full object-cover rounded-lg aspect-video min-w-[100px] w-[100px]"
														priority
													/>
													<span className="text-sm">{isItems ? suggestion.header : suggestion.title}</span>
												</div>
											</CommandItem>
										))}
									</>
								)}
								<CommandEmpty className="py-0">
									{!loading && suggestions?.items?.length === 0 && (
										<div className="h-28 flex items-center justify-center text-muted-foreground text-sm">
											{debouncedSearchInput === "" ? (
												<div className="flex items-center gap-2 text-darkGray">
													<SearchIcon className="relative bottom-[1px] w-4 h-4" />
													<span>Начните поиск</span>
												</div>
											) : (
												<div className="flex items-center gap-2 text-darkGray flex-col">
													<Scan className="relative bottom-[1px] w-5 h-5" />
													<span>Ничего не найдено</span>
												</div>
											)}
										</div>
									)}
								</CommandEmpty>
							</CommandGroup>
						</div>
					</CommandList>
				</div>
			)}
		</Command>
	);
};

export default SearchBar;
