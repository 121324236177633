"use client";

import api from "@/data/api";

const SiteMessage: React.FC = () => {
	const { data, loading } = api.option.SiteMessage();

	if ((loading && !data) || !data || !data.siteMessage) return null;
	return (
		<div className="flex items-center justify-center bg-accentGreen/20 py-2 px-4 w-full rounded-xl border-accentGreen border font-medium text-center">
			{data.siteMessage}
		</div>
	);
};

export default SiteMessage;
