import type { CollectionResponse, ItemResponse } from "@/data/__generated__/types/generated";

import { toast } from "sonner";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type ItemType = "collection" | "product";

export interface FavoritesState {
	addItem: ({
		data,
		type,
	}: {
		data: CollectionResponse | ItemResponse;
		type: ItemType;
	}) => void;
	collections: CollectionResponse[];
	getTotalItems: () => number;
	products: ItemResponse[];
	removeItem: ({ id, type }: { id: number; type: ItemType }) => void;
}

const useFavorites = create<FavoritesState>()(
	persist<FavoritesState>(
		(set, get) => ({
			collections: [],
			products: [],
			addItem: ({
				data,
				type,
			}: {
				data: CollectionResponse | ItemResponse;
				type: ItemType;
			}) => {
				const listType = type === "collection" ? "collections" : "products";

				set((state) => ({ ...state, [listType]: [...state[listType], data] }));
				toast.success("Товар добавлен в избранное.", {
					position: "top-center",
				});
			},
			removeItem: ({ id, type }: { id: number; type: ItemType }) => {
				const listType = type === "collection" ? "collections" : "products";
				set((state) => ({
					...state,
					[listType]: state[listType].filter((item) => item.id !== id),
				}));
				toast.success("Товар удален из избранного.", {
					position: "top-center",
				});
			},
			getTotalItems: () => {
				const collections = get().collections;
				const products = get().products;
				return collections.length + products.length;
			},
		}),
		{
			name: "favorites-storage",
			storage: createJSONStorage(() => localStorage),
		}
	)
);

export default useFavorites;
