const FooterMap: React.FC = () => {
	return (
		<div className="footer-map-clip mt-6 sm:mt-0">
			<div className="overflow-hidden relative rounded-2xl">
				<iframe
					src="https://yandex.ru/map-widget/v1/?from=mapframe&ll=37.793902%2C55.817747&mode=usermaps&source=mapframe&um=constructor%3AXhDBtO8xOoAlAzR4skLgGOfrzZonO9Wk&utm_source=mapframe&z=10"
					width="100%"
					height="319px"
					allowFullScreen
					className="relative"
					aria-label="Yandex.Map"
					title="Yandex.Map"
				/>
			</div>
		</div>
	);
};

export default FooterMap;
