import { useEffect, useState } from "react";

import { ChevronDown, ChevronRight } from "lucide-react";
import Link from "next/link";

import { Button } from "../../ui/Button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../ui/collapsible";

import api from "@/data/api";

const Categories: React.FC = () => {
	const [isCategoriesOpen, setIsCategoriesOpen] = useState(true);
	const [expandedCategories, setExpandedCategories] = useState<boolean[]>([]);

	const { data, loading } = api.category.Categories();

	useEffect(() => {
		if (data?.categories) {
			setExpandedCategories(new Array(data.categories.length).fill(false));
		}
	}, [data]);

	if ((loading && !data) || !data) return null;

	const handleCategoryToggle = (index: number) => {
		setExpandedCategories((prevState) => prevState.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded)));
	};

	return (
		<Collapsible open={isCategoriesOpen} onOpenChange={setIsCategoriesOpen} className="mt-2">
			<CollapsibleTrigger asChild>
				<Button className="bg-accentGreen h-12 w-full rounded-2xl flex text-lg items-center justify-between px-4 text-white font-medium">
					<span>Каталог товаров</span>
					{isCategoriesOpen ? <ChevronDown className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
				</Button>
			</CollapsibleTrigger>
			<CollapsibleContent>
				<div className="bg-white rounded-2xl w-full py-2 mt-2">
					{data.categories.map((category, index) => (
						<Collapsible
							key={index}
							open={expandedCategories[index] || false}
							onOpenChange={() => handleCategoryToggle(index)}
						>
							<CollapsibleTrigger asChild>
								<Button className="font-medium h-10 flex items-center justify-between w-full px-4 bg-white text-primaryBlack hover:text-accentGreen hover:bg-white">
									<span>{category.title}</span>
									{expandedCategories[index] ? (
										<ChevronDown className="w-4 h-4" />
									) : (
										<ChevronRight className="w-4 h-4" />
									)}
								</Button>
							</CollapsibleTrigger>
							<CollapsibleContent>
								<div className="flex flex-col pl-6 pr-4">
									{category.childCategories?.map((subcategory) => (
										<Link
											className="font-medium py-1 sm:hover:text-primaryBlack"
											href={`/${subcategory.slug}`}
											key={subcategory.slug}
										>
											{subcategory.title}
										</Link>
									))}
								</div>
							</CollapsibleContent>
						</Collapsible>
					))}
				</div>
			</CollapsibleContent>
		</Collapsible>
	);
};

export default Categories;
