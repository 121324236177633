"use client";

import { NAV_MENU_ITEMS } from "@/constants";
import { cn } from "@/libs/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";

const NavLinks: React.FC = () => {
	const pathname = usePathname();
	return (
		<div className="w-full h-14 bg-white/50 flex justify-center items-center sm:hidden">
			<div className="flex items-center w-full max-w-[1232px] mx-auto justify-between h-full px-4">
				<ul className="flex gap-10 lg:hidden w-full items-center h-full">
					{NAV_MENU_ITEMS.map((item) => {
						const isActive = pathname.includes(item.href);
						return (
							<li
								key={item.title}
								className={cn("font-medium text-darkGray uppercase text-sm", isActive && "text-accentGreen")}
							>
								<Link href={item.href}>{item.title}</Link>
							</li>
						);
					})}
				</ul>
				{/* <Link href="/sale" className="h-full">
					<button
						type="button"
						className="relative ml-auto border hover:border-red-600 duration-500 group cursor-pointer text-red-50  overflow-hidden h-full w-56  bg-red-800 p-2 flex justify-center items-center "
					>
						<div className="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-900 delay-150 group-hover:delay-75" />
						<div className="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-800 delay-150 group-hover:delay-100" />
						<div className="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-700 delay-150 group-hover:delay-150" />
						<div className="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-600 delay-150 group-hover:delay-200" />
						<div className="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-500 delay-150 group-hover:delay-300" />
						<p className="z-10 flex font-bold uppercase ">Распродажа</p>
					</button>
				</Link> */}
			</div>
		</div>
	);
};

export default NavLinks;
