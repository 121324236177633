import type { IconNameType } from "@RDN794312/xplitka-icons";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { HEADER_ADRESSES, HEADER_CONTACTS } from "@/constants";
import DropdownContacts from "@/presentation/components/DropdownContacts";
import HeaderLogo from "@/presentation/components/ui/HeaderLogo";
import { ThemeColor } from "@/types";

const HeaderContacts: React.FC = () => {
	return (
		<div className="flex items-center w-full py-4">
			<div className="flex flex-1 items-center gap-6 xl:gap-0">
				<HeaderLogo />
				<div className="flex items-center xl:ml-0 ">
					<ul className="font-medium text-lg flex items-center gap-4 xl:text-base">
						{HEADER_CONTACTS.map((contact) => {
							return (
								<li key={contact.id} className="whitespace-nowrap">
									<a
										href={contact.href}
										className={
											contact.id === "whatsapp"
												? "w-[168px] h-[40px] bg-accentGreen rounded-xl flex items-center justify-center text-base text-white hover:text-white"
												: "flex items-center"
										}
									>
										<XPlitkaIcon
											name={contact.icon as IconNameType}
											size={24}
											color={contact.id === "whatsapp" ? ThemeColor.white : ThemeColor.accentGreen}
										/>
										<span className="ml-2 text-base">{contact.text}</span>
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<div className="ml-auto flex items-center gap-8 lg:hidden">
				{HEADER_ADRESSES.map((adress) => {
					return (
						<div className="flex flex-col" key={adress.region}>
							<span className="font-medium text-sm text-darkGray">{adress.region}</span>
							<a
								className="font-medium text-sm flex items-center"
								target="_blank"
								rel="noopener noreferrer"
								href={adress.mapUrl}
							>
								{adress.street}
								<XPlitkaIcon color={ThemeColor.accentGreen} name="arrowTopRight" size={24} />
							</a>
							<a className="text-lg font-medium xl:text-base" href={adress.href}>
								{adress.phoneString}
							</a>
						</div>
					);
				})}
			</div>
			<DropdownContacts className="hidden lg:block" />
		</div>
	);
};

export default HeaderContacts;
