"use client";

import { useEffect } from "react";

import { usePathname, useSearchParams } from "next/navigation";

const Metrika: React.FC = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		if (!window.ym) return;
		const url = `${pathname}?${searchParams}`;
		window.ym(9819136, "hit", url);
	}, [pathname, searchParams]);
	return null;
};

export default Metrika;
