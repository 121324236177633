/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

type UseLockedBodyOutput = [boolean, (locked: boolean) => void];

function useScrollLock(initialLocked = false): UseLockedBodyOutput {
	const [locked, setLocked] = useState(initialLocked);

	useEffect(() => {
		if (!locked) {
			return;
		}

		const documentWidth = document.documentElement.clientWidth;
		const scrollBarWidth = Math.abs(window.innerWidth - documentWidth);

		const originalOverflow = document.body.style.overflow;
		const originalPaddingRight = document.body.style.paddingRight;
		document.body.style.overflow = "hidden";

		if (scrollBarWidth) {
			document.body.style.paddingRight = `${scrollBarWidth}px`;
		}
		return () => {
			document.body.style.overflow = originalOverflow;

			if (scrollBarWidth) {
				document.body.style.paddingRight = originalPaddingRight;
			}
		};
	}, [locked]);

	useEffect(() => {
		if (locked !== initialLocked) {
			setLocked(initialLocked);
		}
	}, [initialLocked]);

	return [locked, setLocked];
}

export default useScrollLock;
