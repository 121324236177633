type HeaderActionsArgs = {
	isCategoriesOpen: boolean;
	setActiveMenu: React.Dispatch<React.SetStateAction<"header" | "navbar" | null>>;
	setCategoriesOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setHoveredCategoryId: React.Dispatch<React.SetStateAction<null | number>>;
	setHoveredLetter: React.Dispatch<React.SetStateAction<null | string>>;
};

const HeaderActions = ({
	isCategoriesOpen,
	setCategoriesOpen,
	setHoveredCategoryId,
	setHoveredLetter,
	setActiveMenu,
}: HeaderActionsArgs) => {
	const handleOpenCategories = (type: "header" | "navbar") => {
		setCategoriesOpen(!isCategoriesOpen);
		setActiveMenu(isCategoriesOpen ? null : type);
	};

	const handleCloseCategories = () => {
		setCategoriesOpen(false);
		setActiveMenu(null);
	};

	const handleHoverCategory = (id: null | number) => () => {
		setHoveredCategoryId(id);
	};

	const handleHoverBrand = (letter: null | string) => () => {
		setHoveredLetter(letter);
	};

	return {
		handleCloseCategories,
		handleHoverCategory,
		handleOpenCategories,
		handleHoverBrand,
	};
};

export default HeaderActions;
