import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";

type MetroAdressBlock = {
	className?: string;
	variant?: "dark" | "light";
};

const MetroAdressBlock: React.FC<MetroAdressBlock> = ({ variant = "light", className }) => {
	return (
		<div
			className={cn(
				"flex mt-4 gap-8 font-medium sm:flex-col sm:gap-6",
				variant === "dark" ? "text-primaryBlack" : "text-white",
				className
			)}
		>
			<div className={cn("flex flex-col  gap-1")}>
				<span className="text-darkGray font-medium text-sm">Север, Москва</span>
				<div className="flex items-center">
					<img src="/static/icons/metro.svg" alt="metro icon" />
					<span className="ml-1">Алтуфьево</span>
				</div>
				<div className="flex items-center">
					<span>ул. Белозерская, 23Г</span>
					<XPlitkaIcon name="arrowTopRight" color={ThemeColor.accentGreen} />
				</div>
				<a className="text-lg font-medium" href="tel:+7 (495) 236-80-00">
					+7 (495) 236-80-00
				</a>
			</div>
			<div className="flex flex-col gap-1">
				<span className="text-darkGray font-medium text-sm">Восток, Москва</span>
				<div className="flex items-center">
					<img src="/static/icons/metro.svg" alt="metro icon" />
					<span className="ml-1">Новокосино</span>
				</div>
				<div className="flex items-center">
					<span>ул. Салтыковская, 7Г, ТЦ Авалон</span>
					<XPlitkaIcon name="arrowTopRight" color={ThemeColor.accentGreen} />
				</div>
				<a className="text-lg font-medium" href="tel:+7 (495) 324-11-11">
					+7 (495) 324-11-11
				</a>
			</div>
		</div>
	);
};

export default MetroAdressBlock;
