"use client";

import { useState } from "react";

import api from "@/data/api";

import Link from "next/link";
import BrandsList from "./components/BrandsList";
import CatalogCategories from "./components/CatalogCategories";
import HeaderContacts from "./components/Contacts";
import CurrentUser from "./components/CurrentUser";
import FixedNavbar from "./components/FixedNavbar";
import Navigation from "./components/Navigation";
import SiteMessage from "./components/SiteMessage";
import NavLinks from "./components/nav-links";
import useActions from "./hooks";

const Header: React.FC = () => {
	const { data: categoriesData } = api.category.Categories();
	const { data: manufacturersData } = api.manufacturers.ManufacturersGroup();
	const [isCategoriesOpen, setCategoriesOpen] = useState(false);
	const [hoveredCategoryId, setHoveredCategoryId] = useState<null | number>(null);
	const [hoveredLetter, setHoveredLetter] = useState<null | string>(null);
	const [activeMenu, setActiveMenu] = useState<"header" | "navbar" | null>(null);

	const { handleCloseCategories, handleHoverCategory, handleOpenCategories } = useActions({
		isCategoriesOpen,
		setCategoriesOpen,
		setHoveredCategoryId,
		setHoveredLetter,
		setActiveMenu,
	});

	return (
		<>
			<NavLinks />
			<header className="flex items-center w-full md:hidden flex-col relative z-20 max-w-[1232px] px-4 mx-auto">
				<CurrentUser />
				<HeaderContacts />
				<div className="flex w-full gap-2 my-4">
					<CatalogCategories
						categories={categoriesData?.categories}
						isCategoriesOpen={isCategoriesOpen && activeMenu === "header"}
						handleOpenCategories={() => handleOpenCategories("header")}
						handleCloseCategories={handleCloseCategories}
						handleHoverCategory={handleHoverCategory}
						hoveredCategoryId={hoveredCategoryId}
					/>
					<Navigation />
				</div>
				<div className="flex items-center gap-2 w-full py-3 lg:hidden">
					<Link href="/sale" className="h-[56px] min-w-[187px]">
						<button
							type="button"
							className="relative z-0 ml-auto border hover:border-red-600 duration-500 group cursor-pointer text-red-50  overflow-hidden h-full w-[187px]  bg-red-800 p-2 flex justify-center items-center rounded-xl"
						>
							<div className="absolute z-10 w-48 h-48 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-900 delay-150 group-hover:delay-75" />
							<div className="absolute z-10 w-40 h-40 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-800 delay-150 group-hover:delay-100" />
							<div className="absolute z-10 w-32 h-32 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-700 delay-150 group-hover:delay-150" />
							<div className="absolute z-10 w-24 h-24 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-600 delay-150 group-hover:delay-200" />
							<div className="absolute z-10 w-16 h-16 rounded-full group-hover:scale-150 transition-all  duration-500 ease-in-out bg-red-500 delay-150 group-hover:delay-300" />
							<p className="z-10 flex font-bold uppercase ">Распродажа</p>
						</button>
					</Link>
					{/* <Button asChild className="h-[56px] min-w-[187px] bg-white shadow-sm text-primaryBlack hover:bg-white">
						<Link href="/manufacturers">Все бренды</Link>
					</Button> */}
					<BrandsList manufacturers={manufacturersData?.manufacturersGroups} hoveredLetter={hoveredLetter} />
				</div>
				<SiteMessage />
				<FixedNavbar
					categories={categoriesData?.categories}
					manufacturers={manufacturersData?.manufacturersGroups}
					handleCloseCategories={handleCloseCategories}
					handleHoverCategory={handleHoverCategory}
					handleOpenCategories={() => handleOpenCategories("navbar")}
					hoveredCategoryId={hoveredCategoryId}
					hoveredLetter={hoveredLetter}
					isCategoriesOpen={isCategoriesOpen && activeMenu === "navbar"}
				/>
			</header>
		</>
	);
};

export default Header;
