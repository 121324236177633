import type { IconNameType } from "@RDN794312/xplitka-icons";

import { useEffect, useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";
import Link from "next/link";

import useFavorites from "@/hooks/useFavorites";
import { ThemeColor } from "@/types";

const FavoritesButton: React.FC = () => {
	const [isMounted, setIsMounted] = useState(false);
	const [favoriteIcon, setFavoriteIcon] = useState("favoriteStroke");
	const favorites = useFavorites();

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<Link
			href="/favorites"
			onMouseEnter={() => setFavoriteIcon("favoriteFilled")}
			onMouseLeave={() => setFavoriteIcon("favoriteStroke")}
			className="bg-white rounded-xl min-w-[48px] h-12 flex justify-center items-center relative text-accentGreen favoritesButton sm:w-[44px] sm:h-[44px]"
			aria-label="Избранное"
		>
			<XPlitkaIcon name={favoriteIcon as IconNameType} color={ThemeColor.accentGreen} size={24} />
			{isMounted && favorites.getTotalItems() > 0 && (
				<span className="bg-primaryBlack text-xs absolute -top-[10px] -right-[10px] rounded-full text-white px-2 py-1">
					{favorites.getTotalItems()}
				</span>
			)}
		</Link>
	);
};

export default FavoritesButton;
