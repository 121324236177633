import { XPlitkaIcon } from "@RDN794312/xplitka-icons";
import Image from "next/image";

import { ThemeColor } from "@/types";

type LogoContactsProps = {
	openContacts: () => void;
};

const LogoContacts: React.FC<LogoContactsProps> = ({ openContacts }) => {
	return (
		<div className="flex items-center justify-between w-full">
			<a href="/" className="flex items-center">
				<Image
					width={35}
					height={35}
					priority
					className="mr-3.5"
					src="/static/images/lightSquareLogo.svg"
					alt="Square Logo"
				/>
				<Image width={87} height={20} priority src="/static/images/lightLogoText.svg" alt="Text Logo" />
			</a>
			<button
				onClick={openContacts}
				name="contacts"
				type="button"
				aria-label="Контакты"
				className="flex items-center justify-center gap-2 h-10 rounded-xl border-[2px] border-mediumGray w-full max-w-[130px] font-medium text-base"
			>
				<XPlitkaIcon name="contacts" color={ThemeColor.accentGreen} size={22} className="relative bottom-[1px]" />
				<span>Контакты</span>
			</button>
		</div>
	);
};

export default LogoContacts;
