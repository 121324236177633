import Link from "next/link";

import { NAV_MENU_ITEMS } from "@/constants";

const MenuLinks: React.FC = () => {
	return (
		<ul className="flex flex-col gap-4 mt-4">
			{NAV_MENU_ITEMS.map((item) => {
				return (
					<li key={item.title} className="font-medium">
						<Link href={item.href}>{item.title}</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default MenuLinks;
